

const BrandingBar = () => {
    return (
        <div className="branding">
                <h1>
                    <img src="../images/logo.png" alt="logo"></img>
                    <b className="logo-color-1 logo-text">guild</b><b className="logo-color-2 logo-text">.LFG</b>
                </h1>
                <h3 className="desktop-wrapper">
                    <b>Find new friends to play games with</b>
                </h3>
        </div>
    );
};

export default BrandingBar;