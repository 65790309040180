import { createContext, useRef, useEffect, useState, useCallback } from "react";
import useWS from "../hooks/UseWS";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useLocation } from "react-router-dom";

const NotifContext = createContext({});

export const NotifSocketProvider = ({ children }) => {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [ newNotifs, setNewNotifs ] = useState(0);
    const [ notifs, setNotifs ] = useState(null);
    const [ chats, setChats ] = useState(null);
    const [ unreadMessages, setUnreadMessages ] = useState([]);
    const [ incomingMsg, setIncomingMsg ] = useState(null);
    const [ selectedChat, setSelectedChat ] = useState(null);

    const msgRef = useRef(unreadMessages);
    const chatsRef = useRef(chats);
    const location = useLocation();

    useEffect(() => {
        chatsRef.current = chats;
    }, [chats]);

    useEffect(() => {
        msgRef.current = unreadMessages;
    }, [unreadMessages]);

    const handleNewMessage = async (e) => {
        if(!auth?.userId) return;
        const msgData = JSON.parse(e.data);
        if('notif' in msgData) {
            setNotifs(prev => ([{...msgData.notif}, ...prev]));
        } else if('text' in msgData) {
            if(msgData.sender !== auth.userId && chatsRef.current.some(chat => chat.id === msgData.chatId) && !msgRef.current.includes(msgData.chatId) && (msgData.chatId !== selectedChat || location.pathname !== "/chats")) {
                setUnreadMessages(prev => ([...prev, msgData.chatId]));
            }
        } else if('friendUpdate' in msgData) {
            refreshChats();
        }
        setIncomingMsg(msgData);
    };

    const refreshChats = useCallback(async () => {
        console.log("refreshing chats...");
        try {
            const response = await axiosPrivate.get('/chats');
            setChats(response.data.map(({recipients, name, _id}) => ({recipients, name, id:_id})));
            setSelectedChat(null);
        } catch (err) {
            setChats(null);
            console.error(err);
        }
    }, [axiosPrivate]);

    const wsRef = useWS('wss://api.guilddotlfg.com/', handleNewMessage);

    return (
        <NotifContext.Provider value={{ selectedChat, setSelectedChat, refreshChats, wsRef, notifs, setNotifs, newNotifs, setNewNotifs, chats, setChats, unreadMessages, setUnreadMessages, incomingMsg, setIncomingMsg, msgRef}}>
            {children}
        </NotifContext.Provider>
    )
}

export default NotifContext;