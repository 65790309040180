import "../CSS/Chats.css";

import { useEffect } from "react";

import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

import ChatList from "../Components/ChatList";
import ChatBox from "../Components/ChatBox";
import useNotifs from "../hooks/useNotifs";

const Chats = () => {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const { setSelectedChat, selectedChat, unreadMessages, setUnreadMessages, chats, incomingMsg, refreshChats } = useNotifs();
    
    useEffect(() => {
        refreshChats();
    }, [refreshChats]);


    useEffect(() => {
        if(incomingMsg && incomingMsg !== null) {
            if ('friendUpdate' in incomingMsg) {
                setSelectedChat(null);
            }
        }
        // eslint-disable-next-line
    }, [incomingMsg, auth.userId]);
    
    useEffect(() => {
        if(chats && chats.length !== 0 && !selectedChat) {
            try {
                const firstChatId = chats[Object.keys(chats)[0]].id;
                setSelectedChat(firstChatId);
                if(unreadMessages.includes(firstChatId)) {
                    const temp = [...unreadMessages];
                    temp.splice(unreadMessages.indexOf(firstChatId), 1);
                    setUnreadMessages(temp);
                }
            } catch (err) {
                console.error(err);
            }
        }
        // eslint-disable-next-line
    }, [selectedChat, axiosPrivate, chats, auth.userId, unreadMessages, setUnreadMessages]);

    return(
        <div className="page-content" style={{marginTop: "68.5px"}}>
            <div className="desktop-wrapper chatlist "> 
                <ChatList
                    chatsList={chats}
                    selectedChat={selectedChat}
                    setSelectedChat={setSelectedChat}
                />
            </div>
            {selectedChat 
                ?   <ChatBox
                        selectedChat={selectedChat}
                        chatsList={chats}
                        setSelectedChat={setSelectedChat}
                    />
                :   <div className="chatbox">
                        <h3 className="text-center my-auto">No Selected User</h3>
                    </div>
            }
        </div>
    );
};

export default Chats;