import { Outlet } from "react-router-dom";

const MobileBlocker = () => {
    return (
        <>
            <div className="w-100 h-100">
                <Outlet />
            </div>{/* 
            <div className="tablet-wrapper p-5 w-100 h-100 ">
                <h1 className="m-auto text-center">Sorry, smaller screen sizes are not yet supported for this site</h1>
            </div> */}
        </>
    );
};

export default MobileBlocker;