import { useEffect, useState } from "react"
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Post from "../Components/Post";
import { Link, useLocation, useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import ListComments from "../Components/ListComments";
import '../CSS/Comments.css';
import useNotifs from "../hooks/useNotifs";
import InfiniteScroll from "react-infinite-scroll-component";


const SingularPost = () => {
    const params = useParams();
    const { auth } = useAuth();
    const { wsRef } = useNotifs();
    const axiosPrivate = useAxiosPrivate();
    const [ post, setPost] = useState({});
    const [ comments, setComments ] = useState([]);
    const [ newComment, setNewComment ] = useState('');
    const [ lastCreatedAt, setLastCreatedAt ] = useState();
    const [ hasMore, setHasMore ] = useState(true);
    const senderName = params.senderName?.toString() || null;
    const postId = params.postId?.toString() || null;
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    
    useEffect(() => {
        const getPost = async () => {
            try {
                const response = await axiosPrivate.get(`/posts/${senderName}/${postId}`);
                setPost(response?.data);
            } catch (err) {
                console.error(err);
            }
        };
        getPost();
    }, [axiosPrivate, postId, senderName]);

    const getComments = async (newPost) => {
        try {
            const lastCreatedChk = newPost ? undefined : lastCreatedAt
            const response = await axiosPrivate.get('/comments', 
                {
                    params: {lastCreatedAt: lastCreatedChk, postId},
                    headers: { 'Content-Type': 'application/json'},
                    withCredentials: true
                }
            );
            if(!lastCreatedAt) {
                setComments(response.data?.foundComments);
            } else {
                setComments(prev => [...prev, ...response.data?.foundComments]);
            }
            setLastCreatedAt(response.data?.foundComments[response.data?.foundComments?.length - 1]?.createdAt);
            setHasMore(response.data?.hasMore);
        } catch (err) {
            setHasMore(false);
            console.error(err);
        }
    };
    
    useEffect(() => { 
        setComments([]);
        setLastCreatedAt(undefined);
        setHasMore(true); 
        getComments(true);
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [post]);

    const postComment = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosPrivate.post('/comments', 
                {postId:post._id, senderId:auth.userId, senderName:auth.username, text:newComment}, 
                {
                    headers: { 'Content-Type': 'application/json'},
                    withCredentials: true
                }
            );
            const { senderId, senderName, text, createdAt, likes, _id} = response.data;
            setComments(prev => ([{senderId, senderName, text, createdAt, likes, _id}, ...prev]));
            setNewComment('');
            if(post.senderId !== auth.userId) {
                const createdNotif = await axiosPrivate.post(`/notifications`, 
                    { notifType: "comment",  notifOwner: post.senderId, notifSender: auth.username, reference: post._id, posterName: post.senderName}, 
                    {
                        headers: { 'Content-Type': 'application/json'},
                        withCredentials: true
                    }
                );
                try {
                    wsRef.send(JSON.stringify({
                        notif: createdNotif.data,
                        receiver: post.senderId,
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
        } catch (err) {
            console.error(err);
        }
    };



    return (
            <div className="w-100 h-100" style={{marginTop: "57.5px"}}>
                <Link className="back-button" to={from} title="Back">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="button-icon mb-1">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
                    </svg>
                </Link>
                {post.text
                    ?   <div className="">
                            <div className="cover mx-auto">
                                <Post 
                                    index="-1" 
                                    post={post} 
                                    axiosPrivate={axiosPrivate}
                                />
                                <form className="add-comment" onSubmit={postComment}>
                                    <input 
                                        type="text" 
                                        placeholder="add comment" 
                                        maxLength="150"
                                        value={newComment}
                                        onChange={(e) => setNewComment(e.target.value)}
                                        className="w-100 p-1"
                                    >                                    
                                    </input>
                                    <button type="submit" title="Add comment">Comment</button>
                                </form>
                            </div>
                            <section className="pt-1">
                                <InfiniteScroll
                                    dataLength={comments.length}
                                    next={() => getComments(false)}
                                    hasMore={hasMore}
                                    loader={<h5>Loading...</h5>}
                                    endMessage={<p>End of comments</p>}
                                >
                                    <ListComments 
                                        post={post} 
                                        comments={comments}
                                        setComments={setComments}
                                        axiosPrivate={axiosPrivate}
                                    />
                                </InfiniteScroll>
                            </section>
                        </div>
                    : <h2 style={{marginTop: "250px"}}>Post Not Found</h2>
                }
            </div>
    );
};

export default SingularPost;