import { Link, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useEffect, useState } from "react";
import useNotifs from "../hooks/useNotifs";
import { format } from "date-fns";

const Comment = ({ post, comments, setComments, index, comment, axiosPrivate }) => {
    const { auth } = useAuth();
    const { wsRef } = useNotifs();
    const [ isLiked, setIsLiked ] = useState(false);
    const [ numLikes, setNumLikes ] = useState(0);
    const location = useLocation();

    useEffect(() => {
        if(comment.likes) {
            setIsLiked(comment.likes.includes(auth.userId));
            setNumLikes(comment.likes.length);
        }
    }, [comment.likes, auth.userId]);
    
    const handleLikes = async (e) => {
        e.preventDefault();
        try {
            await axiosPrivate.put(`/comments/${comment._id}`, 
                { userId:auth.userId}, 
                {
                    headers: { 'Content-Type': 'application/json'},
                    withCredentials: true
                }
            );
            if(comment.senderId !== auth.userId && !isLiked) {
                const createdNotif =  await axiosPrivate.post(`/notifications`, 
                    { notifType: "comment-like",  notifOwner: comment.senderId, notifSender: auth.username, reference: post._id, posterName: post.senderName}, 
                    {
                        headers: { 'Content-Type': 'application/json'},
                        withCredentials: true
                    }
                );
                try {
                    wsRef.send(JSON.stringify({
                        notif: createdNotif.data,
                        receiver: comment.senderId,
                    }));
                } catch (err) {
                    console.error(err);
                }
            }
            setIsLiked(!isLiked);
            setNumLikes(prev => isLiked ? prev - 1 : prev + 1);
        } catch (err) {
            console.error(err);
        }
    };

    const deleteComment = async (e) => {
        e.preventDefault();
        try {
            await axiosPrivate.delete(`/comments/${comment._id}`);
            // GET OUT
            const newCommentList = [...comments];
            newCommentList.splice(index, 1);
            setComments(newCommentList);
        } catch (err) {
            if(!err?.response) {
                console.error('No Server Response');
            } else {
                console.error(err);
            }
        }
    };

    return (
        <div key={index} className="comment">
            <header className="comment-header">
                <Link to={`/people/${comment.senderName}`} title="Go to profile" state={{ from: location }}>{comment.senderName}</Link>
                
            </header>
            <div className="comment-data">
                <button type="button" className={isLiked ? "comment-like-button-filled" : "comment-like-button"} onClick={handleLikes} title="Like comment">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#B7245C" className="">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
                    </svg>
                </button>
                {numLikes}
            </div>
            <p className="time-stamp ms-3">{format(new Date(comment.createdAt), 'h:mm b MM/dd/yy')}</p>
            <section className="comment-body">
                <p className="d-inline-flex">{comment.text}</p>
                <div className="text-end" style={{minHeight: "25px"}}>
                    {comment.senderId === auth.userId
                        ?
                        <button type="button" className="comment-button m-0 p-0" onClick={deleteComment} title="Delete comment">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#B7245C" className="">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                            </svg>
                        </button>
                        : <></>
                    }
                </div>
            </section>
        </div>
    );
};

export default Comment;