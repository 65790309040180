import { useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Link, useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";


const SearchBox = () => {
    const axiosPrivate = useAxiosPrivate();
    const [ search, setSearch ] = useState('');
    const [ users, setUsers ] = useState();
    const [ lastCreatedAt, setLastCreatedAt ] = useState();
    const [ hasMore, setHasMore ] = useState(true);
    const location = useLocation();

    const getSearch = async (newSearch) => {
        try {
            const lastCreatedChk = newSearch ? undefined : lastCreatedAt
            const response = await axiosPrivate.get(`/search`,
                {
                    params: {search, lastCreatedAt: lastCreatedChk},
                    headers: { 'Content-Type': 'application/json'},
                    withCredentials: true
                }
            );
            setUsers(prev => [...prev, ...response.data?.foundUsers]);
            setLastCreatedAt(response.data?.foundUsers[response.data?.foundUsers?.length - 1]?.createdAt);
            setHasMore(response.data?.hasMore);
        } catch (err) {
            console.error(err);
        }
    };


    const handleSearch = async (e) => {
        e.preventDefault();
        setUsers([]);
        setLastCreatedAt(undefined);
        setHasMore(true); 
        getSearch(true);
        window.scrollTo(0, 0);
    };

    return (
        <>
            <form className="send-text search-bar mt-3" onSubmit={handleSearch}>
                <input 
                    type="text" 
                    placeholder="search" 
                    maxLength="150"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="w-100 p-1"
                >                                    
                </input>
                <button type="submit" title="Search">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mb-1 button-icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                    </svg>
                </button>
            </form>
                {users?.length
                    ?   <InfiniteScroll
                            dataLength={users.length}
                            next={() => getSearch(false)}
                            hasMore={hasMore}
                            loader={<h5>Loading...</h5>}
                            endMessage={<p>End of search results</p>}
                        >
                            <ul className="search-results mt-4">
                                {users.map((user, i) => 
                                    <li key={i}  className="my-4">
                                        <Link title="Go to profile" to={`/people/${user?.username}`} state={{ from: location }}>
                                            {user?.username}
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </InfiniteScroll>
                     : <p className="mt-5">nobody here</p>
                }
        </>
    );
};

export default SearchBox;