import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useNotifs from "../hooks/useNotifs";
import { format } from "date-fns";


const Post = ({ posts, setPosts, index, post, axiosPrivate }) => { 
    const navigate = useNavigate();
    const { auth } = useAuth();
    const { wsRef } = useNotifs();
    const [ isLiked, setIsLiked ] = useState(false);
    const [ numLikes, setNumLikes ] = useState(0);
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    useEffect(() => {
        if(post.likes) {
            setIsLiked(post.likes.includes(auth.userId));
            setNumLikes(post.likes.length);
        }
    }, [post.likes, auth.userId]);

    const goToComments = () => {
        navigate(`/post/${post.senderName}/${post._id}`, { state: {from: location} });
    };
    
    const handleLikes = async (e) => {
        e.preventDefault();
        try {
            await axiosPrivate.put(`/posts/${post.senderName}/${post._id}`, 
                { postId:post._id, userId:auth.userId}, 
                {
                    headers: { 'Content-Type': 'application/json'},
                    withCredentials: true
                }
            );
            if(post.senderId !== auth.userId && !isLiked) {
                const createdNotif =  await axiosPrivate.post(`/notifications`, 
                    { notifType: "post-like",  notifOwner: post.senderId, notifSender: auth.username, reference: post._id}, 
                    {
                        headers: { 'Content-Type': 'application/json'},
                        withCredentials: true
                    }
                );
                try {
                    wsRef.send(JSON.stringify({
                        notif: createdNotif.data,
                        receiver: post.senderId,
                    }));
                } catch (err) {
                    console.error(err);
                }
            } 
            setIsLiked(!isLiked);
            setNumLikes(prev => isLiked ? prev - 1 : prev + 1);
        } catch (err) {
            console.error(err);
        }
    };

    const deletePost = async (e) => {
        e.preventDefault();
        try {
            await axiosPrivate.delete(`/posts/${post.senderName}/${post._id}`);
            // GET OUT
            if (index === "-1") {
                navigate(from);
            } else {
                const newPostList = [...posts];
                newPostList.splice(index, 1);
                setPosts(newPostList);
            }
        } catch (err) {
            if(!err?.response) {
                console.error('No Server Response');
            } else {
                console.error(err);
            }
        }
    };
    
    const formatText = (text) => {
        const lines = text.split('\n');
        return(
            <>
                {lines.map((line, index) => (
                    <p className="m-0" key={index}>{line}</p>
                ))}
            </>
        );
    };

    return (
        <div key={index} className={index === "-1" ? "post my-0" : "post on-post-list"}>
            <header className="post-header">
                <Link to={`/people/${post.senderName}`} title="Go to profile" state={{ from: location }}>{post.senderName}</Link>
            </header>

            <div className="post-data">
                {numLikes}
                <button type="button" className={isLiked ? "like-button-filled" : "like-button"} onClick={handleLikes} title="Like post">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#B7245C" className="">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
                    </svg>
                </button>
                <button type="button" className="comment-button" onClick={goToComments} title="Go to comments">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#B7245C" className="">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.068.157 2.148.279 3.238.364.466.037.893.281 1.153.671L12 21l2.652-3.978c.26-.39.687-.634 1.153-.67 1.09-.086 2.17-.208 3.238-.365 1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                    </svg>
                </button>
                {post.comments? post.comments.length : 0}
            </div>
            
            <section className="post-body">
                <Link to={`/post/${post.senderName}/${post._id}`} state={{ from: location }} title="View post">
                    {formatText(post.text)}
                </Link>
            </section>

            <p className="time-stamp" style={{"marginLeft":"30px"}}>{format(new Date(post.createdAt), 'h:mm b MM/dd/yy')}</p>
            <div className="d-flex justify-content-end" style={{minHeight: "25px"}}>
                {post.senderId === auth.userId
                    ?
                    <button type="button" className="comment-button me-4" onClick={deletePost} title="Delete post">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#B7245C" className="">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                        </svg>
                    </button>
                    : <></>
                }
            </div>
        </div>
    );
};

export default Post;