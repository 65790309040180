import useNotifs from "../hooks/useNotifs";
import useAuth from "../hooks/useAuth";

const ChatList = ({ chatsList, selectedChat, setSelectedChat }) => {
    const { unreadMessages, setUnreadMessages } = useNotifs();
    const { auth } = useAuth();

    const adjustChatName = (chatName) => {
        const index = chatName.indexOf(" and ") + 1;
        chatName = chatName.substring(0, index) + "" + chatName.substring(index + 3);
        chatName = chatName.replace(" " + auth.username + " ", "");
        return chatName.trim();
    };

    return (
        <ul className=" ">
            {chatsList != null
                ? chatsList.map(chat => (
                        <li 
                            key={chat.id}
                            className={selectedChat===chat.id 
                                ? "selected-chat" 
                                : unreadMessages.includes(chat.id) 
                                    ?   "new-message"
                                    :   ""
                                } 
                            onClick={() => {
                                if(unreadMessages.includes(chat.id)) {
                                    const temp = [...unreadMessages];
                                    temp.splice(unreadMessages.indexOf(chat.id), 1);
                                    setUnreadMessages(temp);
                                }
                                setSelectedChat(chat.id);
                            }}
                            title="Open chat"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="comment-button mb-1 me-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 0 1-.923 1.785A5.969 5.969 0 0 0 6 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337Z" />
                            </svg>
                            {adjustChatName(chat.name)}
                        </li> 
                    ))
                : <li className="m-auto">No Availible Chats</li>
            }
        </ul>
    );
};

export default ChatList;